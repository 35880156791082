import { apiDatetimeToDate } from "@warrenio/api-spec/conversion";
import { TODO } from "../../dev/Todo.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminLinkButton } from "../AdminLinkButton.tsx";
import { adminAllStoragePoolsQueryAtom } from "../buckets/storagePoolsQuery.ts";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { locationField, numberField } from "../table_fields/standardFields.tsx";
import { adminAllHostsQueryAtom, type AdminHostWithType } from "./hostQuery.ts";
import { HvOs } from "./HvOs.tsx";
import { StatusBadgeButton } from "./StatusBadge.tsx";

function Vmem({ value }: { value: number }) {
    return value.toFixed(1);
}

function Vcpu({ value }: { value: number }) {
    return value.toFixed(1);
}

const hFields: GqlFieldsOf<
    AdminHostWithType,
    /* No order type since this is not a real query */
    never
> = [
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
    }),
    gf({
        id: "created_at",
        title: "Created At",
        get: (a) => (a.created_at ? apiDatetimeToDate(a.created_at) : undefined),
    }),
    gf({
        id: "name",
        title: "Name",
        get: (a) => a.name,
    }),
    gf({
        id: "ip",
        title: "Host/IP",
        get: (a) => a.ip,
        copyable: true,
    }),
    gf({
        id: "os",
        title: "OS",
        get: (a) => a.os,
        render: HvOs,
    }),
    gf({
        ...numberField,
        id: "usable_vcpu_amount",
        title: "Usable vCPUs",
        get: (a) => a.usable_vcpu_amount,
        render: Vcpu,
    }),
    gf({
        ...numberField,
        id: "vcpu_free",
        title: "Free vCPUs",
        get: (a) => a.vcpu_free,
        render: Vcpu,
    }),
    gf({
        ...numberField,
        id: "usable_vmem_amount",
        title: "Usable vMEM",
        get: (a) => a.usable_vmem_amount,
        render: Vmem,
    }),
    gf({
        ...numberField,
        id: "vmem_free",
        title: "Free vMEM",
        get: (a) => a.vmem_free,
        render: Vmem,
    }),
    gf({
        id: "pool_cpu_model",
        title: "CPU Model",
        get: (a) => a.pool_cpu_model,
        hidden: true,
    }),
    gf({
        id: "pool_uuid",
        title: "Pool UUID",
        get: (a) => a.pool_uuid,
        copyable: true,
        hidden: true,
    }),
    gf({
        id: "pool_name",
        title: "Pool Name",
        get: (a) => a.pool_name,
    }),
    gf({
        id: "storage_pool_uuid",
        title: "Storage Pool UUID",
        get: (a) => a.storage_pool_uuid,
        copyable: true,
        hidden: true,
    }),
    gf({
        id: "storage_pool_name",
        title: "Storage Pool Name",
        get: (a) => a.storage_pool_name,
    }),
    gf({
        id: "resources",
        title: "VMs",
        get: (a) => a,
        render: (item) => (
            <TODO>
                <AdminLinkButton action={undefined} label="VMs" />
            </TODO>
        ),
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.status,
        render: StatusBadgeButton,
    }),
    gf({
        ...locationField,
        get: (a) => a.location,
    }),
];

export function HostsTable() {
    const hosts = useSuspenseQueryAtom(adminAllHostsQueryAtom);
    const storagePools = useSuspenseQueryAtom(adminAllStoragePoolsQueryAtom);

    // Join storage pool to host
    const items = [...hosts.values()].map((host) => ({
        ...host,
        storage_pool_name: storagePools.get(host.storage_pool_uuid)?.display_name,
    }));

    return (
        <GraphqlTable
            title="Hosts"
            fields={hFields}
            getId={(item) => item.uuid}
            useQuery={(variables) => {
                return { data: { items }, loading: false };
            }}
        />
    );
}
